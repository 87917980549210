import React from 'react'
import { Link } from 'gatsby'
import * as styles from './footer.module.css'

import Location from './icons/location'
import Phone from './icons/phone'

const year = new Date().getFullYear()

const footer = () => (
  <footer>
    <ul className={styles.footerNavigation}>
      <li className={styles.footerItem}>
        <Link to="/">Home</Link>
      </li>
      <li className={styles.footerItem}>
        <Link to="/about-us/">About us</Link>
      </li>
      <li className={styles.footerItem}>
        <Link to="/time-and-fees/">Time and Fees</Link>
      </li>
      <li className={styles.footerItem}>
        <Link to="/contact-us/">Contact</Link>
      </li>
    </ul>
    <div className={styles.footerIcons}>
      {/*<a aria-label="Send an email" href="mailto:test@test.fr">
        <Mail />
      </a>*/}
      <a
        aria-label="Location"
        href="https://goo.gl/maps/VXHyvqMuAVAAxVLF7"
        target="_blank"
        rel="noreferrer"
      >
        <Location />
      </a>
      <a aria-label="Phone number" href="tel:043892037">
        <Phone />
      </a>
    </div>
    <div className={styles.footerNavigation}>
      <p className={styles.footerCopyright}>
        © {year} EFKS A'oga Amata Newtown. All rights reserved.
      </p>
    </div>
  </footer>
)

export default footer
