import React from "react";
import { Link } from "gatsby";
import navigationLinks from "../constants/navigationLinks";
import * as styles from "./navigation.module.css";

const Navigation = ({ menuOpen }) => {
  let navigationStyles = {
    display: menuOpen ? "flex" : "none",
  };

  return (
    <nav role="navigation" className="navigation">
      <ul className={styles.navigation}>
        {navigationLinks.map(({ title, url }) => {
          return (
            <li className={styles.navigationItem}>
              <Link to={url}>{title}</Link>
            </li>
          );
        })}
      </ul>
      <ul className={styles.navigationMobile} style={navigationStyles}>
        {navigationLinks.map(({ title, url }) => {
          return (
            <li className={styles.navigationItem}>
              <Link to={url}>{title}</Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navigation;
