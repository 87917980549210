const navigationLinks = [
  {
    title: "About us",
    url: "/about-us/",
  },
  {
    title: "Time and Fees",
    url: "/time-and-fees/",
  },
  {
    title: "Contact",
    url: "/contact-us/",
  },
];
export default navigationLinks;
