import React, { useState } from "react";
import "./base.css";
import Container from "./container";
import Navigation from "./navigation";
import Footer from "./footer";
import { Link } from "gatsby";
import { Slant as Hamburger } from "hamburger-react";

const Template = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Container>
      <header>
        <div className="logo">
          <Link to="/">
            <img
              className="logo__image"
              alt="Aoga Amata logo"
              src="/logo.png"
              width="125px"
              height="93px"
            />
          </Link>
        </div>
        <Navigation menuOpen={menuOpen} />
        <Hamburger toggled={menuOpen} toggle={setMenuOpen} />
      </header>
      {children}
      <Footer />
    </Container>
  );
};

export default Template;
